import { css, html, LitElement, nothing } from 'lit';

import {bodySmallStyles, heading1Styles} from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { repeat } from 'lit/directives/repeat.js';

import '@brightspace-ui/core/components/tabs/tabs';
import '@brightspace-ui/core/components/tabs/tab-panel';

import '../components/fuss/fuss-user-list.js';
import '../components/fuss/fuss-sync-table.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { mapify, tenantTypeIndicator } from '../helpers/helpers.js';

class PMFussTenant extends SkeletonMixin(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      environmentId: { type: String, attribute: 'environment-id' },
      tenantUsers: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      selectStyles,
      heading1Styles,
      bodySmallStyles,
      css`
        :host {
          display: block;
        }
        .decoded {
          white-space: pre-wrap;
          word-break: break-word;
          background-color: var(--d2l-color-ferrite);
          color: var(--d2l-color-sylvite);
          padding: 15px;
          border-radius: 5px;
          margin-bottom: 10px;
        }
        .inline-container {
          display: flex;
          align-items: center;
          padding-bottom: 1rem;
        }
        .inline-container > *:not(:last-child) {
          margin-right: 0.5rem;
        }
        .separator {
          margin: 0 0.25rem;
        }
        .tenant-logo {
          margin-top: 1rem;
        }
        .tenant-details {
          margin: 1rem 0;
          padding: 1rem;
          display: table;
        }
        .tenant-detail {
          display: table-row;
          height: 32px;
        }
        .panel {
          padding-bottom: 24px
        }
        .tenant-detail span {
          font-weight: bold;
          display: table-cell;
          padding-right: 1rem;
          vertical-align: middle;
        }
        .tenant-detail div {
          display: table-cell;
          vertical-align: middle;
        }
        .logo-container {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      `
    ];
  }

  constructor() {
    super();
    this._tenant = {};
    this._newDomain = {};
    this.skeleton = true;
    this._domainsMap = [];
    this.tenantUsers = [];
    this.allUsers = [];
    this.relayState = '/';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    this.tenantUsers = await this.client.fetchTenantUsers(this.environmentId, this.tenantId);
    this._tenant = await this.client.fetchTenantSSO(this.environmentId, this.tenantId);
    this._domain = (this._tenant.ssoDomains.find(x => x.default) || this._tenant.ssoDomains[0] || {}).id;
    this._domainsMap = mapify(this._tenant.ssoDomains);
    this._existingURLs = this._tenant.ssoDomains.map(domain => domain.domain);
    this.skeleton = false;
  }

  get currentDomain() {
    return this._domainsMap[this._domain] || {};
  }

  _changeDomain(e) {
    this._domain = e.target.value;
  }
  _copyGuid() {
    navigator.clipboard.writeText(this.tenantId);
    this.client.createAlert('success', `Copied ${this.tenantId} to clipboard`);
  }

  _updateRelayState(e) {
    this.relayState = e.target.value;
  }

  usersListTemplate() {
    if (!this._tenant?.ssoDomains) return nothing;
    return html`
      <img src="${this._tenant.imageUrl}" alt="Tenant logo" class="tenant-logo" height="75">
      ${this.headerTemplate()}
      <div class="inline-container">
        <select
          id="domain-select"
          aria-label="Choose a domain:"
          .value=${this._domain}
          @change=${this._changeDomain}
          class="d2l-input-select"
        >
          ${repeat(this._tenant.ssoDomains, sso => sso.id, sso => html`
            <option .selected=${sso.id === this._domain} .value=${sso.id}>
              ${sso.domain}
            </option>
          `)}
        </select>
        <span class="separator">/</span>
        <d2l-input-text
          .value="${this.relayState}"
          @change="${this._updateRelayState}"
          placeholder="/"
          label="Path"
          label-hidden
        ></d2l-input-text>
      </div>
      <pm-fuss-users-list
        .users="${this.tenantUsers}"
        relay-state="${this.relayState}"
        tenant-id="${this.tenantId}"
        environment-id="${this.environmentId}"
        login-type="${this.currentDomain.loginType}"
        domain=${this.currentDomain.domain}>
      </pm-fuss-users-list>
    `;
  }

  oldTemplate() {
    return html`
    <div class="logo-container">
        <img src="${this._tenant.imageUrl}" alt="Tenant logo" class="tenant-logo" height="100">
        <span>${tenantTypeIndicator(this._tenant.type)}</span>
      </div>
      <div class="tenant-details d2l-body-small">
        <div class="tenant-detail"><span>Environment</span><div>${this.environmentId}</div></div>
        <div class="tenant-detail"><span>Name</span><div>${this._tenant.name}</div></div>
        <div class="tenant-detail"><span>ID</span><div>${this.tenantId} <d2l-button-icon text="Copy guid" @click="${this._copyGuid}" icon="html-editor:cut"></d2l-button-icon></div></div>
      </div>`
  }

  headerTemplate() {
    return html`
      <d2l-collapsible-panel panel-title="${this._tenant.name}" class="panel">
        <div slot="header" style="align-items: center; display: flex; gap: 0.6rem;">
          <span>${tenantTypeIndicator(this._tenant.type)}</span>
          <p class="d2l-body-small"> - ${this.environmentId} - ${this.tenantId}</p>
        </div>
        <div class="d2l-body-small">
          <div class="tenant-details d2l-body-small">
            <div class="tenant-detail"><span>Approval Model</span><div>${this._tenant.approvalModel}</div></div>
            <div class="tenant-detail"><span>Budget Type</span><div class="decoded">${JSON.stringify(this._tenant.budget, null, 2)}</div></div>
            <div class="tenant-detail"><span>Entitlements</span><div class="decoded">${JSON.stringify(this._tenant.entitlements, null, 2)}</div></div>
          </div>
        </div>
      </d2l-collapsible-panel>`;
  }

  render() {
    return html`
      <d2l-tabs>
        <d2l-tab-panel text="Users">${this.usersListTemplate()}</d2l-tab-panel>
      </d2l-tabs>
    `;
  }

}

window.customElements.define('pm-fuss-tenant', PMFussTenant);
