import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/typography/typography.js';
import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui-labs/navigation/d2l-navigation.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-main-header.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-dropdown-button-custom.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-link-icon.js';
import '@brightspace-ui-labs/navigation/d2l-navigation-separator.js';
import '@brightspace-ui/core/components/alert/alert-toast';
import '@brightspace-ui/core/components/icons/icon.js';

import './route-loader.js';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles';
import { ProviderMixin } from '@brightspace-ui/core/mixins/provider-mixin';
import { RouteReactor } from 'd2l-router/RouteReactor';

import { PMClient } from './client';

class Main extends ProviderMixin(LitElement) {

  static get properties() {
    return {
      loading: { type: Boolean },
      _alert: { type: String },
      _alertOpen: { type : Boolean },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      css`
        :host {
          display: block;
          height: 100%;
        }

        main {
          max-width: 1230px;
          margin: 0 auto;
          width: 100%;
          height: 100%;
        }

        .nav-link {
          margin: 5px;
        }
        .loading-title {
          padding-top: 10px;
        }

        .loading-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          min-height: 100vh;
        }

        html {
          box-sizing: border-box;
        }

        *, *::after, *::before {
          box-sizing: inherit;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
          outline: none;
        }

        html {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 16px;
          font-smooth: auto;
          font-weight: 300;
          line-height: 1.5;
          color: #444;
        }

        body {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100vh;
        }

        .loader {
          position: relative;
          display: grid;
          grid-template-columns: 33% 33% 33%;
          grid-gap: 2px;
          width: 100px;
          height: 100px;
        }
        .loader > div {
          position: relative;
          width: 100%;
          height: 100%;
          background: #AE00FF;
          transform: scale(0);
          transform-origin: center center;
          animation: loader 2s infinite linear;
        }
        .loader > div:nth-of-type(1), .loader > div:nth-of-type(5), .loader > div:nth-of-type(9) {
          animation-delay: 0.4s;
        }
        .loader > div:nth-of-type(4), .loader > div:nth-of-type(8) {
          animation-delay: 0.2s;
        }
        .loader > div:nth-of-type(2), .loader > div:nth-of-type(6) {
          animation-delay: 0.6s;
        }
        .loader > div:nth-of-type(3) {
          animation-delay: 0.8s;
        }

        @keyframes loader {
          0% {
            transform: scale(0);
          }
          40% {
            transform: scale(1);
          }
          80% {
            transform: scale(1);
          }
          100% {
            transform: scale(0);
          }
        }
    `];
  }

  constructor() {
    super();
    this.route = new RouteReactor(this);
    this.loading = true;
    this._alertOpen = false;
    this.client = new PMClient();
    this.provideInstance('d2l-pm-client', this.client);
  }

  connectedCallback() {
    super.connectedCallback();
    this.style.setProperty('--d2l-branding-primary-color', '#72FF77');
    this.style.setProperty('--d2l-color-celestine-minus-1', '#72FF77');
    this.style.setProperty('--d2l-color-celestine', '#AE00FF');
    document.addEventListener('kraken-alert-event', async e => {
      const message = e.detail.message || 'Please try again. If the problem persists, please contact support.';
      const type = e.detail.type || 'critical';
      this._openAlert(type, message);
    });
  }


  async firstUpdated() {
    try {
      if (!this.client.isDev()) this.session = this.session || (await this.client.fetchSession());
      this.client.apiUrl = this.session?.apiUrl;
      document.title = this.session.appName;
      this.loading = false;
    } catch (err) {
      console.log(err);
      const relayState = encodeURIComponent(window.location.pathname);
      location.href = `/api/auth?relayState=${relayState}`;
    }
  }

  _loadingTemplate() {
    return html`
      <main class="loading-container">
        <div class="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="loading-title">Releasing the Kraken...</div>
      </main>
    `;
  }

  _mainTemplate() {
    return html`
        ${this._headerTemplate()}
        <main>
            ${this.route.renderView()}
          <d2l-alert-toast id="alert">${this._alert}</d2l-alert-toast>
        </main>
      `;
  }

  render() {
    return this.loading ? this._loadingTemplate() : this._mainTemplate();
  }

  _openAlert(type, message) {
    this._alert = message;
    const alertTemplate = this.shadowRoot.getElementById('alert');
    if (alertTemplate) {
      alertTemplate.type = type;
      alertTemplate.open = true;
    }
  }

  _headerTemplate() {
    return html`
            <header>
                <d2l-navigation>
                    <d2l-navigation-main-header>
                        <div slot="left" class="d2l-navigation-header-left">
                            <img class="logo" src="assets/img/kraken.png" height="75" alt="">
                            <d2l-navigation-separator></d2l-navigation-separator>
                            <h1 class="d2l-heading-1 pm-title">${this.session.appName}</h1>
                        </div>
                        <div slot="right" class="d2l-navigation-header-right">
                          <d2l-navigation-link-icon class="nav-link" href="/home" icon="tier2:home" text="Home">
                          </d2l-navigation-link-icon>
                          <d2l-navigation-link-icon class="nav-link" href="/" icon="tier2:locations" text="Environments">
                          </d2l-navigation-link-icon>
                          <d2l-navigation-link-icon class="nav-link" href="/idp-info" icon="tier2:quizzing" text="IDP Info">
                          </d2l-navigation-link-icon>
                        </div>
                    </d2l-navigation-main-header>
                </d2l-navigation>
            </header>
        `;
  }

}

window.customElements.define('app-main', Main);
