import { css, html, LitElement, nothing } from 'lit';
import { v4 as uuid } from 'uuid';

import { heading3Styles } from '@brightspace-ui/core/components/typography/styles';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import '@brightspace-ui/core/components/expand-collapse/expand-collapse-content';
import '@brightspace-ui/core/components/icons/icon';
import '@brightspace-ui/core/components/button/button';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/dialog/dialog-confirm';
import '@brightspace-ui/core/components/inputs/input-text';
import '@brightspace-ui/core/components/switch/switch.js';

import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles';

import { cardStyles } from '../../styles/card.js';

import { getDisplayName } from '../../helpers/helpers.js';

class FussUserList extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      readonly: { type: Boolean, attribute: 'readonly' },
      relayState: { type: String, attribute: 'relay-state' },
      tenantId: { type: String, attribute: 'tenant-id' },
      domain: { type: String, attribute: 'domain' },
      environmentId: { type: String, attribute: 'environment-id' },
      environment: { type: Object, reflect: false },
      loginType: { type: String, attribute: 'login-type' },
      users: { type: Array },
      _filteredUsers: { type: Array },
      _loginLink: { type: String },
      _userToRemove: { type: Object },
    };
  }

  static get styles() {
    return [
      heading3Styles,
      inputLabelStyles,
      cardStyles,
      css`
        :host {
          display: block;
        }
        .user-content {
          height: 100%;
          align-self: center;
          display: grid;
          place-items: center;
        }
        .expanded-content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          padding: 24px 0 0 0;
        }
        .show-more-button {
          padding-top: 24px;
          margin: 0 auto;
          display: block;
          text-align: center;
        }
        .save-button {
          text-align: end;
        }
        .logo:hover {
          cursor: pointer;
          transform: scale(1.25);
          transition: transform 0.3s ease-in-out;
        }
        .bold {
          font-weight: bold;
        }
      `];
  }

  constructor() {
    super();
    this.readonly = false;
    this._filteredUsers = [];
    this.relayState = '/';
    this._userToRemove = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  get removeUserDialog() {
    if (!this._userDialog) this._userDialog = this.shadowRoot.getElementById('remove-user-dialog');
    return this._userDialog;
  }

  _openRemoveUserDialog(user) {
    return e => {
      e.stopPropagation();
      this._userToRemove = { ...user };

      // Ensure the dialog opens after rendering updates
      this.updateComplete.then(() => {
        this.removeUserDialog.opened = true;
      });
    };
  }

  async firstUpdated() {

    this.users.sort((a, b) => {
      const aval = getDisplayName(a);
      const bval = getDisplayName(b);
      return aval.localeCompare(bval);
    });
    this._filteredUsers = [...this.users];
    this.environment = await this.client.fetchEnvironment(this.environmentId);
  }

  userAttributeUpdated(user) {
    return async e => {
      const key = e.target.id;
      user[key] = e.target.value;
      this.update();
    };
  }

  updateFussUser(user) {
    return async() => {
      const form = this.shadowRoot.getElementById(`user-form-${user.guid}`);
      const invalid = await form.validate();
      if (invalid.size === 0) {
        // Push the updated user data to the backend
        await this.client.pushToFuss(this.environmentId, this.tenantId, user);

        // Update the user in the list
        const index = this._filteredUsers.findIndex(u => u.guid === user.guid);
        if (index !== -1) {
          // Update the user data and collapse all expanded attributes
          this._filteredUsers[index] = { ...user, expanded: false };
          this._filteredUsers = [...this._filteredUsers]; // Ensure reactivity
        }
      }
    };
  }

  activeStateChange(user) {
    return async e => {
      user.active = e.target.on;
    };
  }

  brazeClicked(guid) {
    return e => {
      e.stopPropagation();
      window.open(`https://dashboard-07.braze.com/users/user_search/65e23e02e3acf1004aa0ce9b?locale=en&query=${guid}`, '_blank');
    };
  }

  amplitudeClicked(guid) {
    return e => {
      e.stopPropagation();
      window.open(`https://app.amplitude.com/analytics/d2l/project/${this.environment?.amplitudeProjectId}/search/user_id%3D${guid}`, '_blank');
    };
  }

  async _removeUser() {
    const ret = await this.client.removeFussUser(this.environmentId, this.tenantId, this._userToRemove);
    if (ret) {
      const index = this._filteredUsers.findIndex(user => user.guid === this._userToRemove.guid);
      this._filteredUsers.splice(index, 1);
      this._filteredUsers = [...this._filteredUsers];
    }

  }

  _userListItemTemplate(user) {
    const toggleExpandedUserAttributes = e => {
      e.stopPropagation();
      const button = e.target;
      const expandedContent = this.shadowRoot.querySelector(`#expanded-attributes_${user.guid}`);
      button.text = expandedContent.expanded ? 'Show more' : 'Show less';
      expandedContent.expanded = !expandedContent.expanded;
    };
    return html`
      <d2l-list-item
        expandable
        ?expanded="${user.expanded}"
        grid
        @click="${this._userClicked(user.guid)}"
        href="javascript:void(0)"
        key="${user.guid}"
        label="${getDisplayName(user)}">
        <profile-image .user="${user}" slot="illustration" meta-attribute="guid"></profile-image>
        <div slot="actions">
          <d2l-button-icon text="Copy login url" @click="${this._copyUrl(user)}" icon="html-editor:table-row-copy"></d2l-button-icon>
          <d2l-button-icon text="Copy guid" @click="${this._copyGuid(user)}" icon="html-editor:cut"></d2l-button-icon>
          <d2l-button-icon text="Open in new window" @click="${this._userClicked(user.guid, true)}" icon="tier1:new-window"></d2l-button-icon>
          <d2l-button-icon text="Remove user" @click="${this._openRemoveUserDialog(user)}"  icon="tier1:close-default"></d2l-button-icon>
        </div>
        <d2l-list @click="${this._stopProp}" slot="nested">
          <d2l-form id="user-form-${user.guid}" @change="${this.userAttributeUpdated(user)}">
            <d2l-switch ?on="${user.active}" @change="${this.activeStateChange(user)}" text="Active"></d2l-switch>
            <img class="logo" @click="${this.brazeClicked(user.guid)}" src="assets/img/braze.png" height="35" alt="">
            ${this.environment?.amplitudeProjectId ? html`<img class="logo" @click="${this.amplitudeClicked(user.guid)}" src="assets/img/amplitude.png" height="35" alt="">` : nothing}
            <div class="expanded-content">
              <d2l-input-text id="thirdPartyUserId" value="${user.thirdPartyUserId}" label="Third party user id" required></d2l-input-text>
              <d2l-input-text id="email" value="${user.email}" label="Email" required></d2l-input-text>
              <d2l-input-text id="firstName" value="${user.firstName}" label="First name" required></d2l-input-text>
              <d2l-input-text id="lastName" value="${user.lastName}" label="Last name" required></d2l-input-text>
              <d2l-input-text id="supervisorDisplayName" value="${user.supervisorDisplayName}" label="Manager name"></d2l-input-text>
              <d2l-input-text id="supervisorThirdPartyUserId" value="${user.supervisorThirdPartyUserId}" label="Manager ID"></d2l-input-text>
            </div>
            <d2l-expand-collapse-content id="expanded-attributes_${user.guid}">
              <div class="expanded-content">
                <d2l-input-text id="preferredLanguage" value="${user.preferredLanguage}" label="Preferred language"></d2l-input-text>
                <d2l-input-text id="preferredName" value="${user.preferredName}" label="Preferred name"></d2l-input-text>
                <d2l-input-text id="jobTitle" value="${user.jobTitle}" label="Job title"></d2l-input-text>
                <d2l-input-text id="department" value="${user.department}" label="Department"></d2l-input-text>
                <d2l-input-text id="employeeId" value="${user.employeeId}" label="Employee ID"></d2l-input-text>
                <d2l-input-text id="workPhone" value="${user.workPhone}" label="Work phone"></d2l-input-text>
                <d2l-input-text id="mobilePhone" value="${user.mobilePhone}" label="Mobile phone"></d2l-input-text>
                <d2l-input-text id="photoUrl" value="${user.photoUrl}" label="Photo URL"></d2l-input-text>
              </div>
            </d2l-expand-collapse-content>
            <d2l-button-subtle
              class="show-more-button"
              type="button"
              @click="${toggleExpandedUserAttributes}"
              text="Show more">
            </d2l-button-subtle>
            <d2l-button class="save-button" primary @click="${this.updateFussUser(user)}">Save</d2l-button>
          </d2l-form>
        </d2l-list>
      </d2l-list-item>`;
  }

  _copyUrl(user) {
    return async e => {
      e.stopPropagation();
      const loginLink = await this._getLoginLink(user.guid);
      navigator.clipboard.writeText(loginLink);
      this.client.createAlert('success', `Copied ${loginLink} to clipboard`);
    };
  }

  _stopProp(e) {
    e.stopPropagation();
  }

  _copyGuid(user) {
    return async e => {
      e.stopPropagation();
      const guid = user.guid;
      navigator.clipboard.writeText(guid);
      this.client.createAlert('success', `Copied ${guid} to clipboard`);
    };
  }

  _userClicked(guid, newWindow = false) {
    return async e => {
      e.stopPropagation();
      const href = await this._getLoginLink(guid);
      if (!href) return;

      if (newWindow) {
        window.open(href);
      } else {
        location.href = href;
      }

    };
  }

  async _getLoginLink(guid) {
    if (this.loginType === 'magic-link') {
      try {
        return await this.client.fetchLoginLink(this.environmentId, this.tenantId, guid, this.domain);
      } catch (err) {
        this.client.createAlert('error', 'Invalid email address for tenant');
        return null;
      }
    }
    const relayState = this.relayState || '/';
    const host = this.client.isDev() ? 'http://localhost:3000' : `https://us-east-1.api.${location.hostname}`;
    return `${host}/api/sso/login/${this.environmentId}/${this.tenantId}?username=${guid}&relayState=${relayState}&host=${this.domain}`;
  }

  _filterUsers(e) {
    this._filteredUsers = this.users.filter(user => {
      const compareValues = [getDisplayName(user), user.thirdPartyUserId, user.email, user.guid];
      return compareValues.some(value => value.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0);
    });
  }

  _addUser() {
    const newUser = {
      guid: uuid().toLowerCase(),
      tenantId: this.tenantId,
      firstName: 'New',
      lastName: 'User',
      expanded: true,
    };
    this.users = [newUser, ...this.users];
    this._filteredUsers = this.users;
  }

  _removeUserDialogTemplate() {
    return html`
      <d2l-dialog id="remove-user-dialog" title-text="Remove user">
        ${this._userToRemove
    ? html`<p>Are you sure you want to remove <span class="bold">${this._userToRemove.firstName} ${this._userToRemove.firstName}</span> with the guid
            <br/><span class="bold">${this._userToRemove.guid}</span>?</p>
          <p>
            It will delete the user in the following systems:
            <ul>
              <li>FUSS</li>
              <li>Wave</li>
              <li>Braze</li>
            </ul>
          </p>`
    : nothing}
        <d2l-button slot="footer" @click="${this._removeUser}" primary data-dialog-action="yes">Yes</d2l-button>
        <d2l-button slot="footer" data-dialog-action>No</d2l-button>
      </d2l-dialog>
    `;
  }

  render() {
    return html`
      <d2l-input-text @keyup="${this._filterUsers}" autofocus placeholder="Search for users" label="Search for users" label-hidden></d2l-input-text>
      <d2l-list>
        <d2l-list-controls slot="controls" slim>
          <d2l-button-subtle text="Add user" icon="tier1:add" @click="${this._addUser}"></d2l-button-subtle>
        </d2l-list-controls>
        ${this._filteredUsers.map(user => this._userListItemTemplate(user))}
      </d2l-list>
      ${this._removeUserDialogTemplate()}
    `;
  }

}

window.customElements.define('pm-fuss-users-list', FussUserList);
