import { css, html, LitElement } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin';

import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';

import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import '../components/environment.js';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';

import { cardStyles } from '../styles/card.js';
import { fuzzyDate } from '../helpers/date.js';

class Deployments extends RequesterMixin(LitElement) {

  static get properties() {
    return {
      _deployments: { type: Array },
      _environment: { type: Object },
      environmentId: { type: String, attribute: 'environment-id' },
    };
  }

  static get styles() {
    return [
      tableStyles,
      heading1Styles,
      cardStyles,
      css`
          :host {
            display: block;
          }
          .environment {
            margin: 10px 0;
          }
          .add-environment-button {
            display: inline-block;
          }
          .environments-header {
            display: inline-block;
          }
          .header {
            align-items: center;
            display: flex;
          }
    `];
  }

  constructor() {
    super();
    this._deployments = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-pm-client');
  }

  async firstUpdated() {
    this._deployments = await this.client.fetchDeployments(this.environmentId);
    this._environment = await this.client.fetchEnvironment(this.environmentId);
  }

  render() {
    const deployments = this._deployments || [];

    return html`
        <div class="header"><h1 class="d2l-heading-1 environments-header">Deployments - ${this._environment?.displayName}</h1></div>
        <d2l-table-wrapper>
          <table class="d2l-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Run number</th>
                <th>Branch</th>
                <th>Started at</th>
              </tr>
            </thead>
            <tbody>
              ${deployments.map(row => html`
                <tr>
                  <td></td>
                  <td>
                    <d2l-link href="${row.html_url}">${row.run_number}</d2l-link>
                  </td>
                  <td>${row.branchName}</td>
                  <td>${fuzzyDate(row.started_on)}</td>
                </tr>
              `)}
            </tbody>
          </table>
        </d2l-table-wrapper>
      `;
  }

}

window.customElements.define('pm-deployments', Deployments);
